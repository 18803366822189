import React, { Component } from "react";
import { Link } from "react-router-dom";
import withNavigateHook from '../withNavigateHook';

class NavLinks extends Component {

    handleClick(e) {
        e.preventDefault();
        this.props.navigation('/');
        window.location.reload(false);
    }

    render() {
        return (
            <ul className="navbar-nav ">
                <li className="nav-item">
                    <Link className={this.props.className} to="/" onClick={e => { this.handleClick(e) }}>Home</Link>
                </li>
                <li className="nav-item">
                    <Link className={this.props.className} to="/blog">Articles</Link>
                </li>
                <li className="nav-item">
                    <Link className={this.props.className} to="/about">About</Link>
                </li>
                <li className="nav-item">
                    <Link className={this.props.className} to="/contact">contact</Link>
                </li>
            </ul>
        );
    }
}

export default withNavigateHook(NavLinks);