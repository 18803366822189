import React, { Component } from "react";

const socialMediaShareEndpoint = "/article/share/";

class SMArticleShare extends Component {

    facebookShare(e, id) {
        e.preventDefault();
        const recordId = id;
        const sm = "facebook";
        const shareDetails = sm.concat("*", id);
        fetch(socialMediaShareEndpoint + shareDetails)
            .then((res) => res.json())
            .then((json) => {
            }).catch(err => { console.log(err) });

        const url = "https://facebook.com/sharer/sharer.php?u=https://themiddlestump.xyz/blog/post/" + recordId
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    twitterShare(e, id) {
        e.preventDefault();
        const recordId = id;
        const sm = "twitter";
        const shareDetails = sm.concat("*", id);
        fetch(socialMediaShareEndpoint + shareDetails)
            .then((res) => res.json())
            .then((json) => {
            }).catch(err => { console.log(err) });

        const url = "https://twitter.com/intent/tweet/?text=Check%20out%20this%20post&url=https://themiddlestump.xyz/blog/post/" + recordId
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    whatsappShare(e, id) {
        e.preventDefault();
        const recordId = id;
        const sm = "whatsapp";
        const shareDetails = sm.concat("*", id);
        fetch(socialMediaShareEndpoint + shareDetails)
            .then((res) => res.json())
            .then((json) => {
            }).catch(err => { console.log(err) });


        const url = "https://api.whatsapp.com/send?text=Check%20out%20this%20post%20https://themiddlestump.xyz/blog/post/" + recordId
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    linkedInShare(e, id) {
        e.preventDefault();
        const recordId = id;
        const sm = "linkedIn";
        const shareDetails = sm.concat("*", id);
        fetch(socialMediaShareEndpoint + shareDetails)
            .then((res) => res.json())
            .then((json) => {

            }).catch(err => { console.log(err) });

        const url = "https://www.linkedin.com/sharing/share-offsite/?url=https://themiddlestump.xyz/blog/post/" + recordId
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    render() {
        return (
            <div className="social__media__animation ">
                <ul className="menu topLeft bg__card-shadow">
                    <li className="share bottom">
                        <i className="fa fa-share-alt"></i>
                        <ul className="list__submenu">
                            <li>
                                <a href="#"
                                    className="facebook"
                                    id="facebook"
                                    onClick={(e) => this.facebookShare(e, this.props.postUrl)}
                                    target="_blank"
                                    rel="noopener">
                                    <i className="fa fa-facebook"></i>
                                </a>
                            </li>
                            <li>
                                <a href="#"
                                    className="linkedin"
                                    id="linkedin"
                                    onClick={(e) => this.linkedInShare(e, this.props.postUrl)}
                                    target="_blank"
                                    rel="noopener">
                                    <i className="fa fa-linkedin"></i>
                                </a>
                            </li>
                            <li>
                                <a href="#"
                                    className="twitter"
                                    id="twitter"
                                    onClick={(e) => this.twitterShare(e, this.props.postUrl)}
                                    target="_blank" rel="noopener">
                                    <i className="fa fa-twitter"></i>
                                </a>
                            </li>
                            <li>
                                <a href="#"
                                    className="whatsapp"
                                    id="whatsapp"
                                    onClick={(e) => this.whatsappShare(e, this.props.postUrl)}
                                    target="_blank"
                                    rel="noopener">
                                    <i className="fa fa-whatsapp"></i>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul >
            </div >
        );
    }
}

export default SMArticleShare;