import React, { Component } from "react";
import { Link } from "react-router-dom";

import withNavigateHook from '../withNavigateHook';

class LogoAds extends Component {

    handleClick(e) {
        e.preventDefault();
        this.props.navigation('/');
        window.location.reload(false);
    }

    render() {
        return (
            <div className="bg-white ">
                <div className="container">
                    <div className="row">
                        <div className=" col-sm-12 col-md-4 my-auto d-none d-sm-block ">
                            <figure className="mb-0">
                                <Link to="/" onClick={e => { this.handleClick(e) }}>
                                    <img src="/images/tmsLogoReverseNoBg.png" alt="" className="img-fluid logo" />
                                </Link>
                            </figure>
                        </div>
                        <div className="col-md-8 d-none d-sm-block ">
                            <figure className="mt-3 ">
                                <Link to="#">
                                    <img src="/images/ads/gsh22sm.jpeg" alt="" className="img-fluid" />
                                </Link>
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withNavigateHook(LogoAds);