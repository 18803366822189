import React, { Component } from "react";
import Swal from 'sweetalert2';

//endpoints
import API_URL from "../../constants/apiurl";
//assets
import tmsLogo from '../../assets/tmsLogoReverseNoBg.png'

class Newsletter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: ''
        }
    }

    handleClick(e) {
        e.preventDefault();
        let formData = this.state;
        fetch(API_URL.newlettersubscription, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formData)
        })
            .then((res) => res.json())
            .then((json) => {
                let message = json;
                if (message === '' || message === null || message === 'null')
                    return;

                //display response alert
                Swal.fire({
                    title: `<img src="${tmsLogo}" alt="" className="img-fluid logo" />`,
                    text: message
                });
            }).catch(err => {
                console.log(err);
            });

        //reset form
        document.getElementById("NLForm").reset();
    }

    render() {
        return (
            <aside className="wrapper__list__article">
                <h4 className="border_section">Newsletter</h4>
                <div className="widget__form-subscribe bg__card-shadow">
                    <h6>
                        Catch up with the most important news and events.
                    </h6>
                    <p><small>Get our latest news and articles delivered to you. Subscribe to our mailing list.</small></p>
                    <form id="NLForm">
                        <div className="input-group ">
                            <input type="text"
                                className="form-control"
                                placeholder="Your email address"
                                required="required"
                                title="your email is required to subscribe"
                                value={this.state.email}
                                onChange={e => this.setState({ email: e.target.value })}
                            />
                            <div className="input-group-append">
                                <button className="btn btn-primary"
                                    type="submit"
                                    onClick={e => { this.handleClick(e) }}>sign up</button>
                            </div>
                        </div>
                    </form>

                </div>
            </aside>
        );
    }
}

export default Newsletter;