import React, { Component } from "react";
import { Link } from 'react-router-dom';
import withNavigateHook from '../withNavigateHook';

//components
import BlogQueryForm from "../header/BlogQueryForm";

class AboutHeader extends Component {

    handleClick(e) {
        e.preventDefault();
        this.props.navigation('/');
        window.location.reload(false);
    }

    render() {
        return (
            <header className="bg-light">
                <div className="navigation-wrap navigation-shadow bg-white">
                    <nav className="navbar navbar-hover navbar-expand-lg navbar-soft">
                        <div className="container">
                            <div className="offcanvas-header">
                                <div data-toggle="modal" data-target="#modal_aside_right" className="btn-md">
                                    <span className="navbar-toggler-icon"></span>
                                </div>
                            </div>
                            <figure className="mb-0 mx-auto">
                                <Link to="/" onClick={e => { this.handleClick(e) }}>
                                    <img src="/images/tmsLogoReverseNoBg.png" alt="Alternate Text" style={{ width: 'auto', height: '80px' }} />
                                </Link>
                            </figure>

                            <div className="collapse navbar-collapse justify-content-between" id="main_nav99">
                                <ul className="navbar-nav ml-auto ">
                                    <li className="nav-item"><Link className="nav-link" to="/" onClick={e => { this.handleClick(e) }}> Home </Link></li>
                                    <li className="nav-item"><Link className="nav-link" to="/blog"> Articles </Link></li>
                                    <li className="nav-item"><Link className="nav-link" to="/about"> About Us </Link></li>
                                    <li className="nav-item"><Link className="nav-link" to="/contact"> contact </Link></li>
                                </ul>

                                <ul className="navbar-nav ">
                                    <li className="nav-item search hidden-xs hidden-sm ">
                                        <Link className="nav-link" to="#">
                                            <i className="fa fa-search"></i>
                                        </Link>
                                    </li>
                                </ul>

                                <div className="top-search navigation-shadow">
                                    <div className="container">
                                        <div className="input-group ">
                                            <BlogQueryForm className="row no-gutters mt-3" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>

                <div id="modal_aside_right" className="modal fixed-left fade" tabIndex="-1" role="dialog">
                    <div className="modal-dialog modal-dialog-aside" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className="widget__form-search-bar  ">
                                    <BlogQueryForm className="row no-gutters" />
                                </div>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <nav className="list-group list-group-flush">
                                    <ul className="navbar-nav ">
                                        <li className="nav-item"><Link className="nav-link  text-dark" to="/"> Home </Link></li>
                                        <li className="nav-item"><Link className="nav-link  text-dark" to="/blog"> Articles </Link></li>
                                        <li className="nav-item"><Link className="nav-link  text-dark" to="/about-us"> About Us </Link></li>
                                        <li className="nav-item"><Link className="nav-link  text-dark" to="/contact"> Contact </Link></li>
                                    </ul>
                                </nav>
                            </div>
                            <div className="modal-footer">
                                <p>Copyright &copy;<script>document.write(new Date().getFullYear());</script> <br /> All rights reserved - <span style={{ color: '#ea7600' }}>themiddlestump.xyz</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default withNavigateHook(AboutHeader);