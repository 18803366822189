import React, { Component } from "react";
import BlogQueryForm from "./BlogQueryForm";

class TopSearchBar extends Component {
    render() {
        return (
            <div className="top-search navigation-shadow">
                <div className="container">
                    <div className="input-group ">
                        <BlogQueryForm className="row no-gutters mt-3" />
                    </div>
                </div>
            </div>
        );
    }
}

export default TopSearchBar;