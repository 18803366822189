import React, { Component } from "react";
import { Link } from "react-router-dom";

import NavLinks from "./NavLinks";
import TopSearchBar from "./TopSearchBar";

class NavigationBar extends Component {
    render() {
        return (
            <div className="navigation-wrap navigation-shadow bg-white">
                <nav className="navbar navbar-hover navbar-expand-lg navbar-soft  ">
                    <div className="container">
                        <div className="offcanvas-header">
                            <div data-toggle="modal" data-target="#modal_aside_right" className="btn-md">
                                <span className="navbar-toggler-icon"></span>
                            </div>
                        </div>
                        <figure className="mb-0 mx-auto d-block d-sm-none sticky-logo">
                            <Link to="/">
                                <img src="/images/tmsLogoReverseNoBg.png" alt="" className="img-fluid logo" />
                            </Link>
                        </figure>

                        <div className="collapse navbar-collapse justify-content-between" id="main_nav99">
                            <NavLinks className="nav-link" />
                            <ul className="navbar-nav ">
                                <li className="nav-item search hidden-xs hidden-sm ">
                                    <Link className="nav-link" to="#">
                                        <i className="fa fa-search"></i>
                                    </Link>
                                </li>
                            </ul>
                            <TopSearchBar />
                        </div>
                    </div>
                </nav>
            </div>
        );
    }
}

export default NavigationBar;