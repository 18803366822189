import React, { Component } from "react";

class AsideWrapper extends Component {
    render() {
        return (
            <aside className="wrapper__list__article">
                <h4 className="border_section">{this.props.widgetName}</h4>
                {this.props.children}
            </aside>
        );
    }
}

export default AsideWrapper;