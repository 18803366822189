import React, { Component } from "react";
import Header from "../component/body/IndexHeader";
import Chevron from "../component/footer/Chevron";
import Posts from "../component/body/Posts";
import Footer from "../component/footer/Footer";

class Home extends Component {
    state = {
        loading: true
    }

    componentDidMount() {
        this.setState({
            loading: false
        })
    }

    render() {
        return (
            <div>
                <Header />
                <Posts />
                <Chevron />
                <Footer />
            </div>
        )
    }
}

export default Home;