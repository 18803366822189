import React, { Component } from "react";
import BlogQueryForm from "./BlogQueryForm";
import NavLinks from "./NavLinks";

class MobileModal extends Component {
    render() {
        return (
            <div id="modal_aside_right" className="modal fixed-left fade" tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-dialog-aside" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="widget__form-search-bar  ">
                                <BlogQueryForm className="row no-gutters" />
                            </div>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <nav className="list-group list-group-flush">
                                <NavLinks className="nav-link text-dark" />
                            </nav>
                        </div>
                        <div className="modal-footer">
                            <p>Copyright &copy;<script>document.write(new Date().getFullYear());</script> <br /> All rights reserved - <span>themiddlestump.xyz</span></p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MobileModal;