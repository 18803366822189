import React, { Component } from "react";

const date = new Date();
const dayText = date.toLocaleString('en-us', { weekday: 'long' });
const month = date.toLocaleDateString('en-us', { month: 'long' });
const dayNum = date.toLocaleDateString('en-us', { day: 'numeric' });
const year = date.toLocaleDateString('en-us', { year: 'numeric' });
const formattedDate = dayText.concat(", ").concat(month).concat(" ")
    .concat(dayNum).concat(", ").concat(year);

class DateChild extends Component {
    render() {
        return (
            <div className="topbar-text">{formattedDate}</div>
        );
    }
}

export default DateChild;