import React, { Component } from "react";
import { Link } from "react-router-dom";

class MainAds extends Component {
    render() {
        return (
            <aside className="wrapper__list__article">
                <Link to="#">
                    <figure>
                        <img src="/images/ads/gsh22big.jpeg" alt="" className="img-fluid" />
                    </figure>
                </Link>
            </aside>
        );
    }
}

export default MainAds;