import React from "react";

function Chevron() {
    return (
        <button id="return-to-top">
            <i className="fa fa-chevron-up"></i>
        </button>
    );
}

export default Chevron;