import React, { Component } from "react";
import { Link } from "react-router-dom";
import AsideWrapper from "./AsideWrapper";
import Newsletter from "./NewsLetter";
import MainAd from "./MainAds";
import Archives from "./Archives";
//endpoints
import API_URL from "../../constants/apiurl";

const topNewsCategory = ['National News', 'Women', 'Musings'];
const otherNewsCategory = ['National News', 'Women', 'Musings', 'Silly Point', 'League', 'Development'];

class Posts extends Component {

    constructor(props) {
        super(props);
        var recentPost = { postUrl: '', postImageUrl: '', postTitle: '', dateFormattedFull: '' }
        this.state = {
            navRecentPosts: [recentPost, recentPost, recentPost, recentPost, recentPost, recentPost, recentPost],
            navRecentPostsDataLoaded: false,
            NationalNewsTopNews: {},
            NationalNewsTopNewsLoaded: false,
            WomenTopNews: {},
            WomenTopNewsLoaded: false,
            MusingsTopNews: {},
            MusingsTopNewsLoaded: false,
            NationalNewsOtherNews: [],
            NationalNewsOtherNewsLoaded: false,
            WomenOtherNews: [],
            WomenOtherNewsLoaded: false,
            MusingsOtherNews: [],
            MusingsOtherNewsLoaded: false,
            SillyPointOtherNews: [],
            SillyPointOtherNewsLoaded: false,
            LeagueOtherNews: [recentPost, recentPost, recentPost],
            LeagueOtherNewsLoaded: false,
            DevelopmentOtherNews: [],
            DevelopmentOtherNewsLoaded: false,
            archives: [],
            archivesLoaded: false,
            tags: [],
            tagsLoaded: false
        }
    }

    componentDidMount() {
        fetch(API_URL.recentPost)
            .then((res) => res.json())
            .then((json) => {
                const nrp = [];
                json.forEach((element) => {
                    var data = { postUrl: '', postImageUrl: '', postTitle: '', dateFormattedFull: '' };
                    data.postTitle = element.title;
                    data.postUrl = element.url;
                    data.postImageUrl = element.image_url;
                    data.dateFormattedFull = element.date_formatted_full;
                    nrp.push(data);
                });
                this.setState({
                    navRecentPosts: nrp,
                    navRecentPostsDataLoaded: true
                });
            }).catch(err => { console.log(err) });

        topNewsCategory.forEach(category => {
            fetch(API_URL.topPostEndpoint + category)
                .then((res) => res.json())
                .then((json) => {
                    switch (category) {
                        case "National News":
                            this.setState({
                                NationalNewsTopNews: json,
                                NationalNewsTopNewsLoaded: true
                            });
                            break;
                        case "Women":
                            this.setState({
                                WomenTopNews: json,
                                WomenTopNewsLoaded: true
                            });
                            break;
                        default:
                            this.setState({
                                MusingsTopNews: json,
                                MusingsTopNewsLoaded: true
                            });
                    }
                }).catch(err => { console.log(err) });
        });

        otherNewsCategory.forEach(category => {
            fetch(API_URL.otherPostEndpoint + category)
                .then((res) => res.json())
                .then((json) => {
                    switch (category) {
                        case "National News":
                            this.setState({
                                NationalNewsOtherNews: json,
                                NationalNewsOtherNewsLoaded: true
                            });
                            break;
                        case "Women":
                            this.setState({
                                WomenOtherNews: json,
                                WomenOtherNewsLoaded: true
                            });
                            break;
                        case "Musings":
                            this.setState({
                                MusingsOtherNews: json,
                                MusingsOtherNewsLoaded: true
                            });
                            break;
                        case "Silly Point":
                            this.setState({
                                SillyPointOtherNews: json,
                                SillyPointOtherNewsLoaded: true
                            });
                            break;
                        case "League":

                            const nrp = [];
                            json.forEach((element) => {
                                var data = { postUrl: '', postImageUrl: '', postTitle: '', dateFormattedFull: '' };
                                data.postTitle = element.title;
                                data.postUrl = element.url;
                                data.postImageUrl = element.image_url;
                                data.dateFormattedFull = element.date_formatted_full;
                                nrp.push(data);
                            });
                            this.setState({
                                LeagueOtherNews: nrp,
                                LeagueOtherNewsLoaded: true
                            });
                            break;
                        default:
                            this.setState({
                                DevelopmentOtherNews: json,
                                DevelopmentOtherNewsLoaded: true
                            });
                    }
                }).catch(err => { console.log(err) });
        });

        fetch(API_URL.archEndpoint)
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    archives: json,
                    archivesLoaded: true
                })
            }).catch(err => { console.log(err) });

        fetch(API_URL.tagsEndpoint)
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    tags: json,
                    tagsLoaded: true
                })
            }).catch(err => { console.log(err) });
    }

    render() {
        return (
            <section className="bg-content">
                <div className="container">
                    <div className="row">
                        <div className="col-md-8">
                            <RecentPosts data={this.state} />
                            <figure className="mt-4 mb-4">
                                <Link to="#">
                                    <img src="/images/partners/partners.jpg" alt="partner" className="img-fluid" />
                                </Link>
                            </figure>


                            <WrapperListArticle category="Cricket Nigeria">
                                <div className="row ">
                                    <div className="col-lg-6 pd-0">
                                        <TopNews category="National News" data={this.state} />
                                        <OtherNews category="National News" data={this.state} />
                                    </div>
                                    <div className="col-lg-6 pd-0">
                                        <TopNews category="Women" data={this.state} />
                                        <OtherNews category="Women" data={this.state} />
                                    </div>
                                </div>
                            </WrapperListArticle>

                            <WrapperListArticle category="League">
                                <LeaguePost data={this.state} />
                            </WrapperListArticle>
                            <WrapperListArticle category="Silly Point">
                                <SillyPoint data={this.state} />
                            </WrapperListArticle>
                        </div>

                        <div className="col-md-4">
                            <aside className="wrapper__list__article">
                                <div className="wrapper__list__article-small">
                                    <TopNews category="Musings" data={this.state} />
                                    <OtherNews category="Musings" data={this.state} />
                                </div>
                            </aside>

                            <Newsletter />

                            <div className="sidebar-section">
                                <NewsCards category="Development News" data={this.state} />
                                <MainAd />
                                <AsideWrapper widgetName="Tags">
                                    <Tags data={this.state} />
                                </AsideWrapper>
                                <AsideWrapper widgetName="Archives">
                                    <Archives data={this.state} />
                                </AsideWrapper>
                            </div>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </section>
        );
    }
}

class RecentPosts extends Component {

    render() {
        const { navRecentPosts } = this.props.data;

        return (
            <div className="card__post-carousel">
                <div className="item">
                    <div className="card__post">
                        <div className="card__post__body">
                            <Link to={"/blog/post/" + navRecentPosts[0].postUrl}>
                                <img src={navRecentPosts[0].postImageUrl} className="img-fluid" alt="" />
                            </Link>
                            <div className="card__post__content bg__post-cover">
                                <div className="card__post__category">Headlines</div>
                                <div className="card__post__title">
                                    <h2>
                                        <Link to={"/blog/post/" + navRecentPosts[0].postUrl}>{navRecentPosts[0].postTitle}</Link>
                                    </h2>
                                </div>
                                <div className="card__post__author-info">
                                    <ul className="list-inline">
                                        <li className="list-inline-item">
                                            <span>{navRecentPosts[0].dateFormattedFull}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="card__post">
                        <div className="card__post__body">
                            <Link to={"/blog/post/" + navRecentPosts[1].postUrl}>
                                <img src={navRecentPosts[1].postImageUrl} className="img-fluid" alt="" />
                            </Link>
                            <div className="card__post__content bg__post-cover">
                                <div className="card__post__category">Headlines</div>
                                <div className="card__post__title">
                                    <h2>
                                        <Link to={"/blog/post/" + navRecentPosts[1].postUrl}>{navRecentPosts[1].postTitle}</Link>
                                    </h2>
                                </div>
                                <div className="card__post__author-info">
                                    <ul className="list-inline">
                                        <li className="list-inline-item">
                                            <span>{navRecentPosts[1].dateFormattedFull}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="card__post">
                        <div className="card__post__body">
                            <Link to={"/blog/post/" + navRecentPosts[2].postUrl}>
                                <img src={navRecentPosts[2].postImageUrl} className="img-fluid" alt="" />
                            </Link>
                            <div className="card__post__content bg__post-cover">
                                <div className="card__post__category">Headlines</div>
                                <div className="card__post__title">
                                    <h2>
                                        <Link to={"/blog/post/" + navRecentPosts[2].postUrl}>{navRecentPosts[2].postTitle}</Link>
                                    </h2>
                                </div>
                                <div className="card__post__author-info">
                                    <ul className="list-inline">
                                        <li className="list-inline-item">
                                            <span>{navRecentPosts[2].dateFormattedFull}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="card__post">
                        <div className="card__post__body">
                            <Link to={"/blog/post/" + navRecentPosts[3].postUrl}>
                                <img src={navRecentPosts[3].postImageUrl} className="img-fluid" alt="" />
                            </Link>
                            <div className="card__post__content bg__post-cover">
                                <div className="card__post__category">Headlines</div>
                                <div className="card__post__title">
                                    <h2>
                                        <Link to={"/blog/post/" + navRecentPosts[3].postUrl}>{navRecentPosts[3].postTitle}</Link>
                                    </h2>
                                </div>
                                <div className="card__post__author-info">
                                    <ul className="list-inline">
                                        <li className="list-inline-item">
                                            <span>{navRecentPosts[3].dateFormattedFull}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="card__post">
                        <div className="card__post__body">
                            <Link to={"/blog/post/" + navRecentPosts[4].postUrl}>
                                <img src={navRecentPosts[4].postImageUrl} className="img-fluid" alt="" />
                            </Link>
                            <div className="card__post__content bg__post-cover">
                                <div className="card__post__category">Headlines</div>
                                <div className="card__post__title">
                                    <h2>
                                        <Link to={"/blog/post/" + navRecentPosts[4].postUrl}>{navRecentPosts[4].postTitle}</Link>
                                    </h2>
                                </div>
                                <div className="card__post__author-info">
                                    <ul className="list-inline">
                                        <li className="list-inline-item">
                                            <span>{navRecentPosts[4].dateFormattedFull}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="card__post">
                        <div className="card__post__body">
                            <Link to={"/blog/post/" + navRecentPosts[5].postUrl}>
                                <img src={navRecentPosts[5].postImageUrl} className="img-fluid" alt="" />
                            </Link>
                            <div className="card__post__content bg__post-cover">
                                <div className="card__post__category">Headlines</div>
                                <div className="card__post__title">
                                    <h2>
                                        <Link to={"/blog/post/" + navRecentPosts[5].postUrl}>{navRecentPosts[5].postTitle}</Link>
                                    </h2>
                                </div>
                                <div className="card__post__author-info">
                                    <ul className="list-inline">
                                        <li className="list-inline-item">
                                            <span>{navRecentPosts[5].dateFormattedFull}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="item">
                    <div className="card__post">
                        <div className="card__post__body">
                            <Link to={"/blog/post/" + navRecentPosts[6].postUrl}>
                                <img src={navRecentPosts[6].postImageUrl} className="img-fluid" alt="" />
                            </Link>
                            <div className="card__post__content bg__post-cover">
                                <div className="card__post__category">Headlines</div>
                                <div className="card__post__title">
                                    <h2>
                                        <Link to={"/blog/post/" + navRecentPosts[6].postUrl}>{navRecentPosts[6].postTitle}</Link>
                                    </h2>
                                </div>
                                <div className="card__post__author-info">
                                    <ul className="list-inline">
                                        <li className="list-inline-item">
                                            <span>{navRecentPosts[6].dateFormattedFull}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class TopNews extends Component {

    notLoaded() {
        return <div className="article__entry">
            <div className="article__image">

            </div>
            <div className="article__content">

            </div>
        </div>
    }

    load(topNews, category) {
        return (
            <div className="article__entry">
                <div className="article__image">
                    <Link to={"/blog/post/" + topNews.url} >
                        <img src={topNews.image_url} className="img-fluid" alt="" />
                    </Link>
                </div>
                <div className="article__content">
                    <div className="article__category">{category}</div>
                    <ul className="list-inline">
                        <li className="list-inline-item">
                            <span className="text-primary text-capitalize">{topNews.date_formatted_full}</span>
                        </li>
                    </ul>
                    <h5>
                        <Link to={"/blog/post/" + topNews.url}>{topNews.title}</Link>
                    </h5>
                    <p>{topNews.content.substring(0, 141)}</p>
                    <Link to={"/blog/post/" + topNews.url} className="btn btn-outline-primary mb-4 text-capitalize"> read more</Link>
                </div>
            </div>
        );
    }

    render() {
        const { NationalNewsTopNews, NationalNewsTopNewsLoaded,
            WomenTopNews, WomenTopNewsLoaded,
            MusingsTopNews, MusingsTopNewsLoaded } = this.props.data;
        const category = this.props.category;
        switch (category) {
            case "National News":
                if (!NationalNewsTopNewsLoaded)
                    return this.notLoaded();
                return this.load(NationalNewsTopNews, category);
            case "Women":
                if (!WomenTopNewsLoaded)
                    return this.notLoaded();
                return this.load(WomenTopNews, category);
            default:
                if (!MusingsTopNewsLoaded)
                    return this.notLoaded();
                return this.load(MusingsTopNews, category);
        }

    }
}

class OtherNews extends Component {

    notLoaded() {
        return <div className="mb-3">
            <div className="card__post card__post-list"></div>
        </div>;
    };

    load(otherNews) {
        return (
            <div>
                {otherNews.map((rec, key) =>
                    <div key={key} className="mb-3">
                        <div className="card__post card__post-list">
                            <div className="image-sm">
                                <Link to={"/blog/post/" + rec.url}>
                                    <img src={rec.image_url} className="img-fluid" alt="" />
                                </Link>
                            </div>
                            <div className="card__post__body ">
                                <div className="card__post__content">
                                    <div className="card__post__author-info mb-2">
                                        <ul className="list-inline">
                                            <li className="list-inline-item">
                                                <span className="text-primary text-capitalize">{rec.date_formatted_full}</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="card__post__title">
                                        <h6>
                                            <Link to={"/blog/post/" + rec.url}>{rec.title}</Link>
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }

    render() {
        const { NationalNewsOtherNews, NationalNewsOtherNewsLoaded,
            WomenOtherNews, WomenOtherNewsLoaded,
            MusingsOtherNews, MusingsOtherNewsLoaded } = this.props.data;
        const category = this.props.category;
        switch (category) {
            case "National News":
                if (!NationalNewsOtherNewsLoaded)
                    return this.notLoaded();
                return this.load(NationalNewsOtherNews);
            case "Women":
                if (!WomenOtherNewsLoaded)
                    return this.notLoaded();
                return this.load(WomenOtherNews);
            default:
                if (!MusingsOtherNewsLoaded)
                    return this.notLoaded();
                return this.load(MusingsOtherNews);
        }
    }
}

class NewsCards extends Component {

    render() {
        const { DevelopmentOtherNews, DevelopmentOtherNewsLoaded } = this.props.data;
        if (!DevelopmentOtherNewsLoaded)
            return <AsideWrapper widgetName={this.props.category}></AsideWrapper>;

        return (
            <AsideWrapper widgetName={this.props.category}>
                {DevelopmentOtherNews.map((rec, key) =>
                    <div key={key} className="card__post__content p-3 card__post__body-border-all">
                        <div className="card__post__category text-capitalize">
                            development
                        </div>
                        <div className="card__post__author-info mb-2">
                            <ul className="list-inline mb-0">
                                <li className="list-inline-item">
                                    <span className="text-primary text-capitalize">{rec.date_formatted_full}</span>
                                </li>
                            </ul>
                        </div>
                        <div className="card__post__title">
                            <h5>
                                <Link to={"/blog/post/" + rec.url}>{rec.title}</Link>
                            </h5>
                        </div>
                        <p className="d-none d-lg-block d-xl-block mb-0">{rec.content.substring(0, 141)}</p>
                    </div>
                )}
            </AsideWrapper>
        );
    }
}

class WrapperListArticle extends Component {
    render() {
        return (
            <div className="wrapper__list__article">
                <h4 className="border_section">{this.props.category}</h4>
                {this.props.children}
            </div>
        );
    }
}

class LeaguePost extends Component {

    render() {
        const { LeagueOtherNews } = this.props.data;
        return (
            <div className="row">
                <div className="col-lg-12 pd-0">
                    <div className="article__entry-carousel-three">
                        <div className="item">
                            <div className="article__entry">
                                <div className="article__image">
                                    <Link to={"/blog/post/" + LeagueOtherNews[0].postUrl}>
                                        <img src={LeagueOtherNews[0].postImageUrl} className="img-fluid" alt="" />
                                    </Link>
                                </div>
                                <div className="article__content">
                                    <ul className="list-inline">
                                        <li className="list-inline-item">
                                            <span className="text-primary">{LeagueOtherNews[0].dateFormattedFull}</span>
                                        </li>
                                    </ul>
                                    <h5>
                                        <Link to={"/blog/post/" + LeagueOtherNews[0].postUrl}>{LeagueOtherNews[0].postTitle}</Link>
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="article__entry">
                                <div className="article__image">
                                    <Link to={"/blog/post/" + LeagueOtherNews[1].postUrl}>
                                        <img src={LeagueOtherNews[1].postImageUrl} className="img-fluid" alt="" />
                                    </Link>
                                </div>
                                <div className="article__content">
                                    <ul className="list-inline">
                                        <li className="list-inline-item">
                                            <span className="text-primary">{LeagueOtherNews[1].dateFormattedFull}</span>
                                        </li>
                                    </ul>
                                    <h5>
                                        <Link to={"/blog/post/" + LeagueOtherNews[1].postUrl}>{LeagueOtherNews[1].postTitle}</Link>
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div className="item">
                            <div className="article__entry">
                                <div className="article__image">
                                    <Link to={"/blog/post/" + LeagueOtherNews[2].postUrl}>
                                        <img src={LeagueOtherNews[2].postImageUrl} className="img-fluid" alt="" />
                                    </Link>
                                </div>
                                <div className="article__content">
                                    <ul className="list-inline">
                                        <li className="list-inline-item">
                                            <span className="text-primary">{LeagueOtherNews[2].dateFormattedFull}</span>
                                        </li>
                                    </ul>
                                    <h5>
                                        <Link to={"/blog/post/" + LeagueOtherNews[2].postUrl}>{LeagueOtherNews[2].postTitle}</Link>
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class SillyPoint extends Component {

    render() {
        const { SillyPointOtherNewsLoaded, SillyPointOtherNews } = this.props.data;
        return (
            <div className="wrapp__list__article-responsive">
                {!SillyPointOtherNewsLoaded ?
                    <div className="card__post card__post-list card__post__transition mt-30"></div> :
                    SillyPointOtherNews.map((rec, key) =>
                        <div key={key} className="card__post card__post-list card__post__transition mt-30">
                            <div className="row">
                                <div className="col-md-5">
                                    <div className="card__post__transition">
                                        <Link to={"/blog/post/" + rec.url}>
                                            <img src={rec.image_url} className="img-fluid" alt="" />
                                        </Link>
                                    </div>
                                </div>
                                <div className="col-md-7 my-auto pl-0">
                                    <div className="card__post__body ">
                                        <div className="card__post__content  ">
                                            <div className="card__post__category ">
                                                Silly Point
                                            </div>
                                            <div className="card__post__author-info mb-2">
                                                <ul className="list-inline">
                                                    <li className="list-inline-item">
                                                        <span className="text-primary text-capitalize">{rec.date_formatted_full}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="card__post__title">
                                                <h5>
                                                    <Link to={"/blog/post/" + rec.url}>{rec.title}</Link>
                                                </h5>
                                                <p className="d-none d-lg-block d-xl-block mb-0">{rec.content.substring(0, 141)}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        );
    }
}

class Tags extends Component {

    render() {
        const { tagsLoaded, tags } = this.props.data;
        if (!tagsLoaded)
            return <div className="blog-tags p-0">
                <ul className="list-inline"></ul>
            </div>;

        return (
            <div className="blog-tags p-0">
                <ul className="list-inline">
                    {tags.map((tag, key) =>
                        <li key={key} className="list-inline-item">
                            <Link to={"/blog/tags/" + tag}>{"#" + tag}</Link>
                        </li>
                    )}
                </ul>
            </div>
        );
    }

}

export default Posts;