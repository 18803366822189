import React, { Component } from "react";
import { Link } from "react-router-dom";
import DateChild from "./DateChild";

class TopBar extends Component {
    render() {
        return (
            <div className="topbar d-none d-sm-block">
                <div className="container ">
                    <div className="row">
                        <div className="col-sm-12 col-md-5">
                            <div className="topbar-left">
                                <DateChild />
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-7">
                            <div className="list-unstyled topbar-right">
                                <ul className="topbar-link">
                                    <li><Link to="#" title="">Career</Link></li>
                                    <li><Link to="/contact" title="Contact Us">Contact Us</Link></li>
                                    <li><Link to="#" title="">Play Our Trivia</Link></li>
                                </ul>
                                <ul className="topbar-sosmed">
                                    <li>
                                        <a href="https://www.facebook.com/themiddlestump.xyz" title="Like and Follow our page" target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook"></i></a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/TheMiddleStump2" title="follow us" target="_blank" rel="noopener noreferrer"><i className="fa fa-twitter"></i></a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/themiddlestump/" title="follow our gram" target="_blank" rel="noopener noreferrer"><i className="fa fa-instagram"></i></a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/company/the-middle-stump/about/" title="Connect with us" target="_blank" rel="noopener noreferrer"><i className="fa fa-linkedin"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TopBar;