import React, { Component } from "react";
import { Link } from "react-router-dom";

//endpoints
import API_URL from '../../constants/apiurl';

class TrendingNews extends Component {
    constructor(props) {
        super(props);

        this.state = {
            trendingNews: [
                { tnUrl: '', tnTitle: '' },
                { tnUrl: '', tnTitle: '' },
                { tnUrl: '', tnTitle: '' },
                { tnUrl: '', tnTitle: '' },
                { tnUrl: '', tnTitle: '' },
                { tnUrl: '', tnTitle: '' },
                { tnUrl: '', tnTitle: '' }
            ],
            dataLoaded: false
        };
    }

    componentDidMount() {
        fetch(API_URL.trendingNewsEndpoint)
            .then((res) => res.json())
            .then((json) => {
                const tn = [];
                json.forEach((element) => {
                    var data = { tnUrl: '', tnTitle: '' };
                    data.tnTitle = element.title;
                    data.tnUrl = `/blog/post/${element.url}`;
                    tn.push(data);
                });
                this.setState(
                    {
                        trendingNews: tn,
                        dataLoaded: true
                    }
                )
            })
            .catch(err => { console.log(err) });
    }

    render() {
        const { trendingNews } = this.state;

        return (
            <div className="bg-white">
                <div className="trending-news pt-4 border-tranding">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="trending-news-inner">
                                    <div className="title">
                                        <i className="fa fa-bolt"></i>
                                        <strong>trending news</strong>
                                    </div>
                                    <div className="trending-news-slider">
                                        <div className="item-single">
                                            <Link to={trendingNews[0].tnUrl}>{trendingNews[0].tnTitle}</Link>
                                        </div>
                                        <div className="item-single">
                                            <Link to={trendingNews[1].tnUrl}>{trendingNews[1].tnTitle}</Link>
                                        </div>
                                        <div className="item-single">
                                            <Link to={trendingNews[2].tnUrl}>{trendingNews[2].tnTitle}</Link>
                                        </div>
                                        <div className="item-single">
                                            <Link to={trendingNews[3].tnUrl}>{trendingNews[3].tnTitle}</Link>
                                        </div>
                                        <div className="item-single">
                                            <Link to={trendingNews[4].tnUrl}>{trendingNews[4].tnTitle}</Link>
                                        </div>
                                        <div className="item-single">
                                            <Link to={trendingNews[5].tnUrl}>{trendingNews[5].tnTitle}</Link>
                                        </div>
                                        <div className="item-single">
                                            <Link to={trendingNews[6].tnUrl}>{trendingNews[6].tnTitle}</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TrendingNews;