const liveServer = "https://server.themiddlestump.xyz";
const baseUrl = process.env.NODE_ENV === 'production' ? `${liveServer}/api/client` : "/api/client";
const API_URL = {
    submitContactForm: baseUrl + '/submit/contact-us',
    newlettersubscription: baseUrl + '/subscribe/newsletter',
    searchPost: baseUrl + '/post/search/',
    submitPostComment: baseUrl + '/submit/comment',
    archEndpoint: baseUrl + '/archives',
    getArticleContent: baseUrl + '/blog/article/',
    getArticleTags: baseUrl + '/blog/article/tags/',
    getArticleComment: baseUrl + '/blog/article/comments/',
    getRelatedArticles: baseUrl + '/blog/article/related/',
    topPostEndpoint: baseUrl + '/top/post/',
    otherPostEndpoint: baseUrl + '/other/post/',
    recentPost: baseUrl + '/recent/post',
    tagsEndpoint: baseUrl + '/tags',
    trendingNewsEndpoint: baseUrl + '/recent/post',
    last15Post: baseUrl + "/blog/list",
    pageNumbers: baseUrl + "/page-number",
    yearPosts: baseUrl + "/year/post/",
    getArticlesWithTags: baseUrl + '/blog/articles/tag/'
}

module.exports = API_URL;