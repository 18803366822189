import React, { Component } from "react";
import { Link } from "react-router-dom";

class Archives extends Component {

    render() {
        const { archivesLoaded, archives } = this.props.data;
        if (!archivesLoaded)
            return <div className="widget widget__category">
                <ul className="list-unstyled "></ul>
            </div>;

        return (
            <div className="widget widget__category">
                <ul className="list-unstyled ">
                    {archives.map((arc, key) =>
                        <li key={key}>
                            <Link to={"/blog/post/all/" + arc.year}>
                                <b>{arc.year}</b>
                                <span className="badge">{"(" + arc.count + ")"}</span>
                            </Link>
                        </li>
                    )}
                </ul>
            </div>
        );
    }
}

export default Archives;