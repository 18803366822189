import React, { Component } from "react";

import AboutHeader from "../component/body/AboutHeader";
import Team from "../component/body/Team";
import Footer from '../component/footer/Footer';
import Chevron from '../component/footer/Chevron';
import teamDetails from '../constants/const';


class About extends Component {
    render() {
        return (
            <div>
                <AboutHeader />
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="wrap__about-us">
                                    <h2>Welcome to TheMiddleStump.</h2>
                                    <h4>It is very thrilling to have you here - because there is so much here to be shared.</h4>
                                    <p>
                                        TheMiddleStump was conceived out of passion: passion to inform, educate and entertain;
                                        and we believe that the same passion will be relayed back to readers through the informative
                                        and instructive contents that will be posted here on TheMiddleStump.
                                    </p>
                                    <figure className="float-left mr-3">
                                        <img src="/images/placeholder/500x400.jpg" alt="" className="img-fluid" />
                                    </figure>
                                    <p>
                                        Explore and discover those things you long to learn about in the Nigerian (and global) cricket space
                                        and relearn the things you thought you knew; and perhaps TheMiddleStump will ignite your own passions too.
                                        For years, very few have served as useful sources of relevant information to those seeking news, contents,
                                        help or advice. TheMiddleStump has come with a mission to give you a taste of what really goes on in the
                                        cricket circle in Nigeria, and also serve as a platform for promising talents to sprout, flourish and express themselves.
                                    </p>
                                    <p>
                                        We are excited to have you join us on this electrifying journey because we aim to serve you with completely refreshing
                                        experience as you explore the cricket-verse with us.
                                    </p>
                                    <p>
                                        WANT TO CONNECT?<br />
                                        Email us at: <a href="mailto:hello@themiddlestump.xyz">hello@themiddlestump.xyz</a>
                                    </p>
                                    <div className="clearfix"></div>

                                    <h2>Our Valuable Team Members</h2>
                                    <div className="team-member row">
                                        {
                                            teamDetails.map((team, key) =>
                                                <Team key={key} image={team.image}
                                                    name={team.name}
                                                    role={team.role}
                                                    facebookUrl={team.facebookUrl}
                                                    twitterUrl={team.twitterUrl}
                                                    instagramUrl={team.instagramUrl}
                                                    linkedinUrl={team.linkedinUrl} />
                                            )}
                                    </div>
                                    <div className="clearfix"></div>

                                    <h2>Our Partners</h2>
                                    <div className="team-member row">
                                        <div className="col-md-12">
                                            <img src={"/images/partners/partners.jpg"} alt="partners" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
                <Chevron />
            </div>
        );
    }
}

export default About;