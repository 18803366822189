import React, { Component } from "react";
import Swal from 'sweetalert2';

import AboutHeader from '../component/body/AboutHeader';
import Footer from '../component/footer/Footer';
import Chevron from '../component/footer/Chevron';
import { Link } from "react-router-dom";

//endpoints
import API_URL from "../constants/apiurl";

//assets
import tmsLogo from '../assets/tmsLogoReverseNoBg.png'

class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {
            names: '',
            email: '',
            message: ''
        }
    }

    handleFormSubmit(e) {
        e.preventDefault();
        let formData = this.state;
        fetch(API_URL.submitContactForm, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formData)
        })
            .then((res) => res.json())
            .then((json) => {
                let msg = json;
                if (msg === '' || msg === null || msg === 'null')
                    return;

                //display response alert
                Swal.fire({
                    title: `<img src="${tmsLogo}" alt="" className="img-fluid logo" />`,
                    text: msg
                });
            }).catch(err => {
                console.log(err);
            });


        //reset form
        this.setState({
            names: '',
            email: '',
            message: ''
        });
    }

    render() {
        return (
            <div>
                <AboutHeader />
                <section className="wrap__contact-form mt-4">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8">
                                <h5>contact us</h5>
                                <form id="contactForm">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="form-group form-group-name">
                                                <label>Your name <span className="required"></span></label>
                                                <input type="text"
                                                    className="form-control"
                                                    required="required"
                                                    value={this.state.names}
                                                    onChange={e => this.setState({ names: e.target.value })}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group form-group-name">
                                                <label>Your email <span className="required"></span></label>
                                                <input type="email"
                                                    className="form-control"
                                                    required=""
                                                    value={this.state.email}
                                                    onChange={e => this.setState({ email: e.target.value })}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Your message </label>
                                                <textarea className="form-control"
                                                    rows="8"
                                                    required="required"
                                                    value={this.state.message}
                                                    onChange={e => this.setState({ message: e.target.value })}
                                                ></textarea>
                                            </div>
                                            <div className="form-group float-right mb-4">
                                                <button type="submit"
                                                    className="btn btn-primary"
                                                    onClick={e => { this.handleFormSubmit(e) }}>
                                                    Submit
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>


                            <div className="col-md-4">
                                <h5>Info location</h5>
                                <div className="wrap__contact-form-office">
                                    <ul className="list-unstyled">
                                        <li>
                                            <span>
                                                <i className="fa fa-home"></i>
                                            </span>
                                            Lagos, Nigeria
                                        </li>
                                        <li>
                                            <span>
                                                <i className="fa fa-phone"></i>
                                                <a href="tel:+2348062179826">(+234) 806 217 9826</a>
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                <i className="fa fa-envelope"></i>
                                                <a href="mailto:hello@themiddlestump.xyz">hello@themiddlestump.xyz</a>
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                <i className="fa fa-globe"></i>
                                                <Link to="/"> www.themiddlestump.com</Link>
                                            </span>
                                        </li>
                                    </ul>

                                    <div className="social__media">
                                        <h5>find us</h5>
                                        <ul className="list-inline">

                                            <li className="list-inline-item">
                                                <a href="https://www.facebook.com/themiddlestump.xyz" target="_blank" rel="noreferrer"
                                                    title="Like and Follow our page" className="btn btn-social rounded text-white facebook">
                                                    <i className="fa fa-facebook"></i>
                                                </a>
                                            </li>
                                            <li className="list-inline-item">
                                                <a href="https://twitter.com/TheMiddleStump2" target="_blank" rel="noreferrer"
                                                    title="follow us" className="btn btn-social rounded text-white twitter">
                                                    <i className="fa fa-twitter"></i>
                                                </a>
                                            </li>
                                            <li className="list-inline-item">
                                                <a href="https://www.instagram.com/themiddlestump/" target="_blank" rel="noreferrer"
                                                    title="follow our gram" className="btn btn-social rounded text-white instagram">
                                                    <i className="fa fa-instagram"></i>
                                                </a>
                                            </li>
                                            <li className="list-inline-item">
                                                <a href="https://www.linkedin.com/company/the-middle-stump/about/" target="_blank" rel="noreferrer"
                                                    title="Connect with us" className="btn btn-social rounded text-white linkedin">
                                                    <i className="fa fa-linkedin"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
                <Chevron />
            </div>
        )
    }
}

export default Contact;