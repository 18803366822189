import React, { Component } from "react";
import withNavigateHook from '../withNavigateHook';
import Swal from 'sweetalert2';

//assets
import tmsLogo from '../../assets/tmsLogoReverseNoBg.png'

class BlogQueryForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchFilter: ''
        }
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick = () => {
        if (this.state.searchFilter.trim() === '') {
            Swal.fire({
                title: `<img src="${tmsLogo}" alt="" className="img-fluid logo" />`,
                text: 'Enter your preferred search keyword(s)'
            });
        } else {
            this.props.navigation('/blog/search/' + this.state.searchFilter);
        }
    }

    render() {
        return (

            <form>
                <div className={this.props.className}>
                    <div className="col">
                        <input className="form-control border-secondary border-right-0 rounded-0"
                            type="text"
                            required
                            placeholder="Type a keyword and hit enter"
                            value={this.state.searchFilter}
                            onChange={e => this.setState({ searchFilter: e.target.value })}
                        />
                    </div>
                    <div className="col-auto">
                        <button className="btn btn-outline-secondary border-left-0 rounded-0 rounded-right"
                            type="button"
                            onClick={e => { this.handleClick(e) }}
                        >
                            <i className="fa fa-search"></i>
                        </button>
                    </div>
                </div>
            </form>
        );
    }
}


export default withNavigateHook(BlogQueryForm);