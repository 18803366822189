import React, { Component } from "react";
import TopBar from "../header/TopBar";
import LogoAds from "../header/LogoAds";
import NavigationBar from "../header/NavigationBar";
import MobileModal from "../header/MobileModal";

class PagesHeader extends Component {
    render() {
        return (
            <header>
                <TopBar />
                <LogoAds />
                <NavigationBar />
                <MobileModal />
            </header>
        );
    }
}

export default PagesHeader;