import React, { Component } from "react";
import { Link, useParams } from "react-router-dom";
import Breadcrumbs from "../component/body/Breadcrumbs";
import PagesHeader from "../component/body/PagesHeader";
import AsideWrapper from "../component/body/AsideWrapper";
import Footer from "../component/footer/Footer";
import Chevron from "../component/footer/Chevron";
import API_URL from "../constants/apiurl";

class BlogList extends Component {
    constructor(props) {
        super(props);
        console.log(`post year passed -> ${this.props.params.id}`);
        this.state = {
            postForTheYear: [],
            loaded: false
        }
    }

    componentDidMount() {
        let year = this.props.params.id;
        fetch(API_URL.yearPosts + year)
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    postForTheYear: json,
                    loaded: true
                });
            }).catch(err => { console.log(err) });
    }

    render() {
        const { loaded, postForTheYear } = this.state;
        return (
            <div>
                <PagesHeader />
                <section>
                    <div className="container">
                        <Breadcrumbs url="/blog" urlTitle="Articles" urlSubTitle={this.props.params.id} />
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="offset-md-1 col-md-10">
                                <AsideWrapper widgetName={this.props.params.id + ' Ariticles'}>
                                    <div className="row">
                                        {!loaded ?
                                            <div></div> :
                                            postForTheYear.map((rec, key) =>
                                                <div className="col-md-4" key={key}>
                                                    <div className="article__entry">
                                                        <div className="article__image">
                                                            <Link to={`/blog/post/${rec.url}`}>
                                                                <img src={rec.image_url} className="img-fluid w-100" alt="" />
                                                            </Link>
                                                        </div>
                                                        <div className="article__content">
                                                            <div className="article__category">{rec.category}</div>
                                                            <ul className="list-inline">
                                                                <li className="list-inline-item">
                                                                    <span className="text-primary text-capitalize">{rec.date_formatted_full}</span>
                                                                </li>
                                                            </ul>
                                                            <h5>
                                                                <Link to={`/blog/post/${rec.url}`}>{rec.title}</Link>
                                                            </h5>
                                                            <p className="d-none d-lg-block d-xl-block mb-0">{rec.content.substring(0, 141)}</p>
                                                            <Link to={`/blog/post/${rec.url}`} className="btn btn-outline-primary mb-4 text-capitalize"> read more</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </AsideWrapper>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </section>
                <Footer />
                <Chevron />
            </div>
        )
    }
}

export default (props) => (<BlogList {...props} params={useParams()} />);