import React, { Component } from "react";
import { Link } from "react-router-dom";
import Swal from 'sweetalert2';

//endpoints
import API_URL from "../../constants/apiurl";
//assets
import tmsLogo from '../../assets/tmsLogoReverseNoBg.png'


class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: ''
        }
    }

    handleClick(e) {
        e.preventDefault();
        let formData = this.state;
        if (formData.email.trim() === '')
            return Swal.fire({
                title: `<img src="${tmsLogo}" alt="" className="img-fluid logo" />`,
                text: 'Email is required. Please enter your email',
                icon: 'error'
            });

        fetch(API_URL.newlettersubscription, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(formData)
        })
            .then((res) => res.json())
            .then((json) => {
                this.setState({ email: '' });
                let message = json;
                if (message === '' || message === null || message === 'null')
                    return;

                //display response alert
                Swal.fire({
                    title: `<img src="${tmsLogo}" alt="" className="img-fluid logo" />`,
                    text: message
                });
            }).catch(err => {
                console.log(err);
            });

        //reset form
        document.getElementById("footerNL").reset();

    }

    render() {
        return (
            <section className="wrapper__section p-0">
                <div className="wrapper__section__components">
                    <footer>
                        <div className="wrapper__footer bg__footer ">
                            <div className=" container">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="widget__footer">
                                            <figure>
                                                <img src="/images/TheMiddleStumpNoBg.png" alt="" className="logo-footer" />
                                            </figure>
                                            <p>
                                                TheMiddleStump is the No. 1 Cricket Blog in Africa. We bring you up to speed with the latest news and happenings in the
                                                cricket world. We double as the platform where cricketers can express their thoughts, feelings and idea about the game
                                                and its happenings.
                                                <br />
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="widget__footer">
                                            <h4 className="footer-title">Connect with us on your favorite Social Media</h4>
                                            <p>
                                                <button className="btn btn-social btn-social-o facebook mr-1">
                                                    <a href="https://www.facebook.com/themiddlestump.xyz" title="Like and Follow our page" style={{ color: 'white' }}>
                                                        <i className="fa fa-facebook"></i>
                                                    </a>
                                                </button>
                                                <button className="btn btn-social btn-social-o twitter mr-1">
                                                    <a href="https://twitter.com/TheMiddleStump2" title="follow us" style={{ color: 'white' }}>
                                                        <i className="fa fa-twitter"></i>
                                                    </a>
                                                </button>
                                                <button className="btn btn-social btn-social-o instagram mr-1">
                                                    <a href="https://www.instagram.com/themiddlestump/" title="follow our gram" style={{ color: 'white' }}>
                                                        <i className="fa fa-instagram"></i>
                                                    </a>
                                                </button>
                                                <button className="btn btn-social btn-social-o linkedin mr-1">
                                                    <a href="https://www.linkedin.com/company/the-middle-stump/about/" title="Connect with us" style={{ color: 'white' }}>
                                                        <i className="fa fa-linkedin"></i>
                                                    </a>
                                                </button>
                                            </p>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <div className="widget__footer">
                                            <h4 className="footer-title">Newsletter</h4>
                                            <div className="widget__form-newsletter ">
                                                <p>Get our latest news and articles delivered to you. Subscribe to our mailing list</p>
                                                <div className="mt-3">
                                                    <form id="footerNL">
                                                        <input type="email"
                                                            className="form-control mb-2"
                                                            placeholder=" Email"
                                                            required="required"
                                                            title="your email is required to subscribe"
                                                            style={{ 'borderRadius': '1rem' }}
                                                            value={this.state.email}
                                                            onChange={e => this.setState({ email: e.target.value })}
                                                        />
                                                        <button className="btn btn-primary btn-block" type="submit"
                                                            style={{ 'borderRadius': '1rem' }}
                                                            onClick={e => { this.handleClick(e) }}
                                                        >subscribe</button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="bg__footer-bottom ">
                            <div className="container">
                                <div className="row flex-column-reverse flex-md-row">
                                    <div className="col-md-6">
                                        <span>
                                            Copyright &copy;<script>document.write(new Date().getFullYear());</script>
                                            <br /> All rights reserved -
                                            <span style={{ color: '#ea7600' }}>themiddlestump.xyz</span>
                                        </span>
                                    </div>
                                    <div className="col-md-6">
                                        <ul className="list-inline ">
                                            <li className="list-inline-item">
                                                <Link to="#">
                                                    privacy
                                                </Link>
                                            </li>
                                            <li className="list-inline-item">
                                                <Link to="/contact">
                                                    contact
                                                </Link>
                                            </li>
                                            <li className="list-inline-item">
                                                <Link to="/about">
                                                    about
                                                </Link>
                                            </li>
                                            <li className="list-inline-item">
                                                <Link to="#">
                                                    faq
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>
            </section>
        );
    }
}

export default Footer;