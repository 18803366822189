import React, { Component } from "react";
import { Link } from "react-router-dom";

class Breadcrumbs extends Component {
    render() {
        return (
            <div className="row">
                <div className="col-md-12">
                    <ul className="breadcrumbs bg-light mb-4">
                        <li className="breadcrumbs__item">
                            <Link className="breadcrumbs__url" to="/">
                                <i className="fa fa-home"></i>Home
                            </Link>
                        </li>
                        <li className="breadcrumbs__item">
                            <Link className="breadcrumbs__url" to={this.props.url}>
                                {this.props.urlTitle}
                            </Link>
                        </li>
                        <li className="breadcrumbs__item breadcrumbs__item--current">
                            <Link to="#" className="breadcrumbs__url">{this.props.urlSubTitle}</Link>
                        </li>
                    </ul>
                </div>
            </div>
        )
    }
}

export default Breadcrumbs;