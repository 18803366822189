import React, { Component } from "react";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import parse from "html-react-parser";
import Swal from 'sweetalert2';

//component
import SMArticleShare from "../component/body/SMArticleShare";
import Breadcrumbs from "../component/body/Breadcrumbs";
import PagesHeader from "../component/body/PagesHeader";
import AsideWrapper from "../component/body/AsideWrapper";
import NewsLetter from "../component/body/NewsLetter";
import MainAds from "../component/body/MainAds";
import Archives from "../component/body/Archives";
import Footer from "../component/footer/Footer";
import Chevron from "../component/footer/Chevron";

//endpoints
import API_URL from "../constants/apiurl";

//assets
import tmsLogo from '../assets/tmsLogoReverseNoBg.png'


const isEmail = (email) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

class BlogArticle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            article: {},
            articleLoaded: false,
            tagkeys: [],
            tagKeysLoaded: false,
            comments: [],
            commentLoaded: false,
            relatedPost: [],
            relatedPostLoaded: false,
            archives: [],
            archivesLoaded: false,
            email: '',
            emailError: null,
            postComment: '',
            postCommentError: null,
            names: '',
            namesError: null,
            postTitle: ''
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();
        let obj = {
            comment: this.state.postComment,
            email: this.state.email,
            names: this.state.names,
            title: this.state.postTitle
        }

        if (obj.comment.trim() === ''
            || obj.email.trim() === ''
            || obj.names.trim() === ''
            || obj.title.trim() === '') {
            console.log(`form not complete`);
            return;
        }

        let reqBody = JSON.stringify(obj);
        fetch(API_URL.submitPostComment, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: reqBody
        })
            .then((res) => res.json())
            .then((json) => {
                this.setState({ email: '', postComment: '', names: '' });
                this.fetchArticleComments(obj.title);

                let message = json;
                if (message === '' || message === null || message === 'null')
                    return;

                //display response alert
                Swal.fire({
                    title: `<img src="${tmsLogo}" alt="" className="img-fluid logo" />`,
                    text: message
                });
            }).catch(err => { console.log(err) });
    }

    handleEmailChange() {
        let email = this.state.email;
        if (!isEmail(email)) {
            this.setState({
                postCommentError: 'Enter a valid email'
            });
        } else {
            this.setState({
                postCommentError: null
            });
        }
    }

    handleNamesChange() {
        let names = this.state.names;
        if (names.trim() === '') {
            this.setState({ namesError: 'Enter valid names' });
        } else {
            this.setState({ namesError: null });
        }
    }

    handleCommentChange() {
        let pc = this.state.postComment;
        if (pc.trim() === '') {
            this.setState({ postCommentError: 'Enter comment' });
        } else {
            this.setState({ postCommentError: null });
        }
    }

    handleRelatedPostClick(e, url) {
        this.makeAllArticleCalls(url);
    }

    fetchArticleComments(title) {
        fetch(API_URL.getArticleComment + title)
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    comments: json,
                    commentLoaded: true
                });
            }).catch(err => { console.log(err) });
    }

    makeAllArticleCalls(url) {
        fetch(API_URL.getArticleContent + url)
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    article: json,
                    articleLoaded: true,
                    postTitle: json.title
                });

                this.fetchArticleComments(json.title);

            }).catch(err => { console.log(err) });

        fetch(API_URL.getArticleTags + url)
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    tagkeys: json,
                    tagKeysLoaded: true
                });
            }).catch(err => { console.log(err) });

        fetch(API_URL.getRelatedArticles + url)
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    relatedPost: json,
                    relatedPostLoaded: true
                });
            }).catch(err => { console.log(err) });
    }

    componentDidMount() {
        const params = this.props.params;
        this.makeAllArticleCalls(params.id);

        fetch(API_URL.archEndpoint)
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    archives: json,
                    archivesLoaded: true
                })
            }).catch(err => { console.log(err) });
    }

    render() {
        const { article, articleLoaded,
            tagkeys, tagKeysLoaded,
            comments, commentLoaded,
            archivesLoaded,
            relatedPost, relatedPostLoaded,
            namesError, postCommentError, emailError } = this.state;
        return (
            <div className="blogArticle">
                <Helmet>
                    {/* default meta tags */}
                    <title>{article.title}</title>
                    <meta name="description" content={article.content} />
                    {/* facebook meta tags */}
                    <meta property="og:url" content={`https://themiddlestump.xyz/blog/post/${article.url}`} />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="TheMiddleStump" />
                    <meta property="og:site_name" content="TheMiddleStump.xyz" />
                    <meta property="og:description" content={article.content} />
                    <meta property="og:image" itemprop="image" content={article.image_url} />
                    <meta property="og:image:secure_url" itemprop="image" content={article.image_url} />
                    <meta property="og:image:width" content="256" />
                    <meta property="og:image:height" content="256" />
                    {/* twitter meta tags */}
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta property="twitter:domain" content="themiddlestump.xyz" />
                    <meta property="twitter:url" content={`https://themiddlestump.xyz/blog/post/${article.url}`} />
                    <meta name="twitter:title" content={article.title} />
                    <meta name="twitter:description" content={article.content} />
                    <meta name="twitter:image" content={article.image_url} />
                    <meta name="twitter:site" content="@themidddlestump2" />
                    <meta name="twitter:creator" content="@themidddlestump2" />
                    <meta name="author" content="TheMiddleStump" />
                </Helmet>
                <PagesHeader />
                <section className="bg-white pb-60">
                    {
                        !articleLoaded ? <div></div> :
                            <div className="container">
                                <Breadcrumbs url="/blog" urlTitle="Blog" urlSubTitle={article.category} />

                                <div className="row">
                                    <div className="col-md-8">

                                        <SMArticleShare postUrl={article.url} />
                                        <ArticleContent data={article} />
                                        {!tagKeysLoaded ?
                                            <div></div> :
                                            <ArticleTags data={tagkeys} />
                                        }

                                        <div id="comments" className="comments-area">
                                            {parseInt(article.comment_count) === 0 ?
                                                <div></div> :
                                                <div>
                                                    <h3 className="comments-title">{article.comment_count + parseInt(article.comment_count) === 1 ? ' comment' : ' comments'}</h3>
                                                    <ol className="comment-list">
                                                        {!commentLoaded ? "" :
                                                            comments.map((comment, key) =>
                                                                <li className="comment" key={key}>
                                                                    <aside className="comment-body">
                                                                        <div className="comment-meta">
                                                                            <div className="comment-author vcard">
                                                                                <img src="/images/avatar.png" className="avatar" alt="avatar" />
                                                                                <b className="fn">{comment.names}</b>
                                                                                <span className="says">says:</span>
                                                                            </div>
                                                                            <div className="comment-metadata">
                                                                                <Link to="">
                                                                                    <span>{comment.date + ' at ' + comment.time}</span>
                                                                                </Link>
                                                                            </div>
                                                                        </div>
                                                                        <div className="comment-content">
                                                                            <p>{comment.comment}</p>
                                                                        </div>
                                                                    </aside>
                                                                </li>
                                                            )
                                                        }
                                                    </ol>
                                                </div>
                                            }

                                            <div className="comment-respond">
                                                <h3 className="comment-reply-title">Leave a comment</h3>
                                                <form id="cForm" className="comment-form">
                                                    <p className="comment-notes">
                                                        <span id="email-notes">Your email address will not be published. </span>
                                                        Required fields are marked
                                                        <span className="required">*</span>
                                                    </p>
                                                    <p className="comment-form-comment">
                                                        <label htmlFor="comment">Comment</label>
                                                        {postCommentError && <span style={{ color: 'red' }}>{postCommentError}</span>}
                                                        <textarea cols="45"
                                                            rows="5"
                                                            maxLength="65525"
                                                            required
                                                            value={this.state.postComment}
                                                            onChange={e => {
                                                                this.setState({ postComment: e.target.value });
                                                                this.handleCommentChange();
                                                            }}
                                                        ></textarea>
                                                        <input id="post" type="hidden" className="form-control" value={article.postTitle} />
                                                    </p>
                                                    <p className="comment-form-author">
                                                        <label>Name <span className="required">*</span></label>
                                                        {namesError && <span style={{ color: 'red' }}>{namesError}</span>}
                                                        <input type="text"
                                                            required="required"
                                                            value={this.state.names}
                                                            onChange={e => {
                                                                this.setState({ names: e.target.value });
                                                                this.handleNamesChange()
                                                            }}
                                                        />
                                                    </p>
                                                    <p className="comment-form-email">
                                                        <label htmlFor="email">Email <span className="required">*</span></label>
                                                        {emailError && <span style={{ color: 'red' }}>{emailError}</span>}
                                                        <input type="email"
                                                            required="required"
                                                            value={this.state.email}
                                                            onChange={e => {
                                                                this.setState({ email: e.target.value });
                                                                this.handleEmailChange()
                                                            }}
                                                        />
                                                    </p>
                                                    <p className="form-submit">
                                                        <input type="submit"
                                                            className="submit"
                                                            value="Post Comment"
                                                            onClick={e => { this.handleSubmit(e) }}
                                                        />
                                                    </p>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="clearfix"></div>

                                    </div>

                                    <div className="col-md-4">
                                        <AsideWrapper widgetName={"Suggested Read"}>
                                            {!relatedPostLoaded ? <div></div> :
                                                <RelatedArticles data={relatedPost} />
                                            }
                                        </AsideWrapper>

                                        <NewsLetter />
                                        <div className="sidebar-section">
                                            <AsideWrapper widgetName="Archives">
                                                {!archivesLoaded ?
                                                    <div></div> :
                                                    <Archives data={this.state} />
                                                }
                                            </AsideWrapper>


                                        </div>
                                        <MainAds />
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                    }
                </section>
                <Footer />
                <Chevron />
            </div>
        );
    }
}

function ArticleContent(props) {
    const article = props.data;
    return (
        <div className="wrap__article-detail">
            <div className="wrap__article-detail-image">
                <figure>
                    <img src={article.image_url} alt="" className="img-fluid" />
                </figure>
            </div>
            <div className="wrap__article-detail-title">
                <h1>{article.title}</h1>
            </div>
            <hr />
            <div className="wrap__article-detail-info">
                <ul className="list-inline">
                    <li className="list-inline-item">
                        <span className="text-primary text-capitalize ml-1">{article.date_formatted_full + " "}</span>
                    </li>
                    <li className="list-inline-item">
                        <span className="text-dark text-capitalize">
                            &nbsp;in
                        </span>
                        <Link to="">{" " + article.category}</Link>
                        ,
                    </li>
                    <li className="list-inline-item ">
                        <span className="mr-1 ml-1">
                            <i className="fa fa-comment"></i>
                            <span>{article.comment_count}</span>
                        </span>
                    </li>
                </ul>
            </div>
            <hr />
            <div className="wrap__article-detail-content">{parse(article.content_full)}</div>
        </div>
    )
}

function ArticleTags(props) {
    const tagkeys = props.data;
    return (
        <div className="blog-tags">
            <ul className="list-inline">
                <li className="list-inline-item">
                    <i className="fa fa-tags"></i>
                </li>
                {tagkeys.map((tag, key) =>
                    <li className="list-inline-item" key={key}>
                        <Link to={"/blog/tags/" + tag}>{'#' + tag}</Link>
                    </li>
                )}
            </ul>
        </div>
    );
}

function RelatedArticles(props) {
    const relatedPost = props.data;
    return (
        <div className="wrapper__list__article-small">
            {relatedPost.map((rec, key) =>
                <div className="mb-3" key={key}>
                    <div className="card__post card__post-list">
                        <div className="image-sm">
                            <Link to={"/blog/post/" + rec.url} onClick={e => { this.handleRelatedPostClick(e, rec.url) }}>
                                <img src={rec.image_url} className="img-fluid" alt="" />
                            </Link>
                        </div>
                        <div className="card__post__body ">
                            <div className="card__post__content">
                                <div className="card__post__author-info mb-2">
                                    <ul className="list-inline">
                                        <li className="list-inline-item">
                                            <span className="text-primary text-capitalize">{rec.date_formatted_full}</span>
                                        </li>
                                    </ul>
                                </div>
                                <div className="card__post__title">
                                    <h6>
                                        <Link to={"/blog/post/" + rec.url} onClick={e => { this.handleRelatedPostClick(e, rec.url) }}>
                                            {rec.title}
                                        </Link>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default (props) => (
    <BlogArticle {...props} params={useParams()} />
);