import React, { Component } from "react";

class Team extends Component {
    render() {
        return (
            <div className="col-md-3">
                <figure className="member"> <img src={this.props.image} className="img-fluid" alt={this.props.name} />
                    <figcaption>
                        <h4>{this.props.name}</h4>
                        <small>{this.props.role}</small>
                        <ul className="list-inline">
                            <li className="list-inline-item">
                                <a href={this.props.facebookUrl}><i className="fa fa-facebook" aria-hidden="true"></i></a>
                            </li>
                            <li className="list-inline-item">
                                <a href={this.props.twitterUrl}><i className="fa fa-twitter" aria-hidden="true"></i></a>
                            </li>
                            <li className="list-inline-item">
                                <a href={this.props.instagramUrl}><i className="fa fa-instagram" aria-hidden="true"></i></a>
                            </li>
                            <li className="list-inline-item">
                                <a href={this.props.linkedinUrl}><i className="fa fa-linkedin" aria-hidden="true"></i></a>
                            </li>
                        </ul>
                    </figcaption>
                </figure>
            </div>
        );
    }
}

export default Team;