import React, { Component } from "react";
import { Link, Outlet, useParams } from "react-router-dom";

import AsideWrapper from "../component/body/AsideWrapper";
import MainAds from "../component/body/MainAds";
import Newsletter from "../component/body/NewsLetter";
import Pagination from "../component/body/Pagination";
import Breadcrumbs from "../component/body/Breadcrumbs";
import PagesHeader from "../component/body/PagesHeader";
import Footer from "../component/footer/Footer";
import Chevron from "../component/footer/Chevron";
import API_URL from "../constants/apiurl";

class Blog extends Component {
    constructor(props) {
        super(props);
        var activeValue = 1;
        console.log(`blog page passed -> ${this.props.params.id}`)
        if (this.props.params.id !== undefined) {
            activeValue = this.props.params.id;
        }

        this.state = {
            blogList: [],
            dataLoaded: false,
            pages: [],
            active: activeValue
        }
    }

    componentDidMount() {
        if (this.state.active === 1) {
            fetch(API_URL.last15Post)
                .then((res) => res.json())
                .then((json) => {
                    this.setState({
                        blogList: json,
                        dataLoaded: true
                    });
                }).catch(err => { console.log(err) });
            fetch(API_URL.pageNumbers)
                .then((res) => res.json())
                .then((json) => {
                    this.setState({
                        pages: json
                    });
                }).catch(err => { console.log(err) });
        } else {
            fetch(API_URL.last15Post + `/${this.state.active}`)
                .then((res) => res.json())
                .then((json) => {
                    this.setState({
                        blogList: json,
                        dataLoaded: true
                    });
                }).catch(err => { console.log(err) });
            fetch(API_URL.pageNumbers)
                .then((res) => res.json())
                .then((json) => {
                    this.setState({
                        pages: json
                    });;
                }).catch(err => { console.log(err) });
        }
    }

    render() {
        const { dataLoaded, blogList } = this.state;
        return (
            <div>
                {/* <Loader /> */}
                <PagesHeader />
                <section>
                    <div className="container">
                        <Breadcrumbs url="/blog" urlTitle="Article" urlSubTitle="Read" />
                    </div>

                    <div className="container">
                        <div className="row">
                            <div className="col-md-8">
                                <AsideWrapper widgetName="Articles">
                                    {dataLoaded ? blogList.map((rec, key) =>
                                        <div key={key} className="card__post card__post-list card__post__transition mt-30">
                                            <div className="row ">
                                                <div className="col-md-5">
                                                    <div className="card__post__transition">
                                                        <Link to={"/blog/post/" + rec.url}>
                                                            <img src={rec.image_url} className="img-fluid w-100" alt="" />
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="col-md-7 my-auto pl-0">
                                                    <div className="card__post__body">
                                                        <div className="card__post__content">
                                                            <div className="card__post__category">{rec.category}</div>
                                                            <div className="card__post__author-info mb-2">
                                                                <ul className="list-inline">
                                                                    <li className="list-inline-item">
                                                                        <span className="text-primary text-capitalize">{rec.date_formatted_full}</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div className="card__post__title">
                                                                <h5>
                                                                    <Link to={"/blog/post/" + rec.url}>{rec.title}</Link>
                                                                </h5>
                                                                <p className="d-none d-lg-block d-xl-block mb-0">{rec.content.substring(0, 141)}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>) :
                                        <div className="card__post card__post-list card__post__transition mt-30"></div>
                                    }
                                </AsideWrapper>
                            </div>
                            <div className="col-md-4">
                                <div className="sidebar-sticky">
                                    <Newsletter />
                                    <MainAds />
                                </div>
                            </div>
                        </div>
                        <Pagination pages={this.state.pages} active={this.state.active} />
                    </div>
                </section>
                <Footer />
                <Chevron />
                <Outlet />
            </div>
        );
    }
}

export default (props) => (
    <Blog {...props} params={useParams()} />);