var teamDetails = [
    {
        image: '/images/team/tshow.jpeg',
        name: 'Tayo Sowole',
        role: 'Co-founder/Editor',
        facebookUrl: '#',
        twitterUrl: '#',
        instagramUrl: '#',
        linkedinUrl: '#'
    },
    {
        image: '/images/team/olumide.jpeg',
        name: 'Olumide Akintokun',
        role: 'Co-founder/Editor',
        facebookUrl: 'https://www.facebook.com/olutokun',
        twitterUrl: 'https://twitter.com/Olutokun82?s=09',
        instagramUrl: 'https://www.instagram.com/olutokun/?hl=en',
        linkedinUrl: 'https://www.linkedin.com/mwlite/in/olumide-akintokun-76155025'
    },
    {
        image: '/images/team/sam.jpeg',
        name: 'Samuel Emehelu',
        role: 'Content Creator & Director',
        facebookUrl: '#',
        twitterUrl: '#',
        instagramUrl: '#',
        linkedinUrl: '#'
    },
    {
        image: '/images/team/zeal.jpeg',
        name: 'Zeal Ibe',
        role: 'Editor/Art Director',
        facebookUrl: '#',
        twitterUrl: '#',
        instagramUrl: '#',
        linkedinUrl: '#'
    },
    {
        image: '/images/team/ricky.jpeg',
        name: 'Richson Tetteh',
        role: 'Media/Publicity Manager',
        facebookUrl: '#',
        twitterUrl: '#',
        instagramUrl: '#',
        linkedinUrl: '#'
    },
    {
        image: '/images/team/ij.jepg',
        name: 'Ijeoma Okigbo',
        role: 'Editor/Content Manager',
        facebookUrl: '#',
        twitterUrl: '#',
        instagramUrl: '#',
        linkedinUrl: '#'
    },
    {
        image: '/images/team/ruddy.png',
        name: 'Emmanuel Israel',
        role: 'Technology Lead/Editor',
        facebookUrl: 'https://www.facebook.com/EmmanuelIsrael07/',
        twitterUrl: 'https://twitter.com/logicRey',
        instagramUrl: 'https://www.instagram.com/logicrey/',
        linkedinUrl: 'https://www.linkedin.com/in/eisrael07/'
    }

];


module.exports = teamDetails;