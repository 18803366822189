import React, { Component } from "react";
import TopBar from "../header/TopBar";
import MobileModal from "../header/MobileModal";
import LogoAds from "../header/LogoAds";
import NavigationBar from "../header/NavigationBar";
import TrendingNews from "./TrendingNews";

class IndexHeader extends Component {

    render() {
        return (
            <header>
                <TopBar />
                <LogoAds />
                <NavigationBar />
                <MobileModal />
                <TrendingNews />
            </header>
        );
    }
}

export default IndexHeader;