import React, { Component } from 'react';
import { Route, Routes } from "react-router-dom";
import { Helmet } from 'react-helmet';

import Home from './pages/Home';
import Blog from './pages/Blog';
import BlogArticle from './pages/BlogArticle';
import Contact from './pages/Contact';
import About from './pages/About';
import BlogList from './pages/BlogList';
import BlogSearch from './pages/BlogSearch';
import BlogSearchWithTag from './pages/BlogSearchWithTag';

class App extends Component {

  render() {
    return (
      <div className="app">
        <Helmet titleTemplate="TheMiddleStump - %s" defaultTitle="TheMiddleStump - Africa's leading Cricket Media Influence">
          <meta name="description" content="Africa's leading Cricket Media Influence. Catch up with exciting cricket news, reports and match analysis" />
          <meta name="keywords" content="TheMiddleStump, Cricket, Cricket Africa, NCF, Nigeria Cricket, Cricket Media, Cricket News, Sports, Africa Cricket, Sport Blog" />
          {/* facebook meta tags */}
          <meta property="og:url" content="https://themiddlestump.xyz" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="TheMiddleStump" />
          <meta property="og:site_name" content="TheMiddleStump.xyz" />
          <meta property="og:description" content="Africa's leading Cricket Media Influence. Catch up with exciting cricket news, reports and match analysis" />
          <meta property="og:image" content="https://themiddlestump.xyz/images/tmsLogoReverseNoBg.png" />
          <meta property="og:image:secure_url" itemprop="image" content="https://themiddlestump.xyz/images/tmsLogoReverseNoBg.png" />
          <meta property="og:image:width" content="256" />
          <meta property="og:image:height" content="256" />
          {/* twitter meta tags */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content="themiddlestump.xyz" />
          <meta property="twitter:url" content="https://themiddlestump.xyz" />
          <meta name="twitter:title" content="TheMiddleStump" />
          <meta name="twitter:description" content="Africa's leading Cricket Media Influence. Catch up with exciting cricket news, reports and match analysis" />
          <meta name="twitter:image" content="https://themiddlestump.xyz/images/tmsLogoReverseNoBg.png" />
          <meta name="twitter:site" content="@themidddlestump2" />
          <meta name="twitter:creator" content="@themidddlestump2" />
          <meta name="author" content="TheMiddleStump" />
        </Helmet>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/post/:id" element={<BlogArticle />} />
          <Route path="/blog/page/:id" element={<Blog />} />
          <Route path="/blog/search/:id" element={<BlogSearch />} />
          <Route path="/blog/tags/:id" element={<BlogSearchWithTag />} />
          <Route path="/blog/post/all/:id" element={<BlogList />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
        </Routes>
      </div>
    )
  }
}

export default App;
