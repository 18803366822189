import React, { Component } from "react";
import { Link } from "react-router-dom";

class Pagination extends Component {

    render() {
        const pages = this.props.pages;
        const active = this.props.active;
        return (
            <div className="pagination-area">
                <div className="pagination wow fadeIn animated"
                    data-wow-duration="2s"
                    data-wow-delay="0.5s"
                    style={{
                        "visibility": 'visible',
                        "animationDuration": '2s',
                        "animationDelay": '0.5s',
                        "animationName": 'fadeIn'
                    }}>
                    <Link to="">&lt;</Link>
                    {(Array.isArray(pages) && pages.length) ?
                        pages.map((page, key) =>
                            <Link key={key} className={parseInt(active) === parseInt(page) ? 'active' : ''}
                                to={"/blog/page/" + page}>{page}</Link>
                        )
                        : <Link to="/blog">1</Link>
                    }
                    <Link to="">&gt;</Link>
                </div>
            </div>
        )
    }
}

export default Pagination;