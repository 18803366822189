import React, { Component } from "react";
import { Link, useParams } from "react-router-dom";

//component
import AboutHeader from "../component/body/AboutHeader";
import Chevron from "../component/footer/Chevron";
import Footer from "../component/footer/Footer";
//endpoint
import API_URL from "../constants/apiurl";

class BlogSearch extends Component {

    constructor(props) {
        super(props);
        var searchFilter = '';
        console.log(`blog search filter passed -> ${this.props.params.id}`)
        if (this.props.params.id !== undefined) {
            searchFilter = this.props.params.id;
        }
        this.state = {
            defaultPage: 'show',
            keyword: searchFilter,
            resultSize: 0,
            searchResult: [],
            dataLoaded: false
        }
    }

    componentDidMount() {
        fetch(API_URL.searchPost + this.state.keyword)
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    searchResult: json,
                    defaultPage: 'noShow',
                    resultSize: json.length,
                    dataLoaded: true
                })
            }).catch(err => { console.log(err) });
    }

    render() {
        const { defaultPage, keyword, dataLoaded, searchResult, resultSize } = this.state;
        return (
            <div>
                <AboutHeader />
                <section>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-8 mx-auto">

                                {defaultPage === 'show' ?
                                    <div className="wrap__search-result">
                                        <div className="wrap__search-result-keyword">
                                            <h5>
                                                Loading results for keyword: <span className="text-primary">{keyword}</span>.
                                            </h5>
                                        </div>
                                    </div>
                                    :
                                    <div className="wrap__search-result">
                                        {resultSize === 0 ?
                                            <div className="wrap__search-result-keyword">
                                                <h5>
                                                    No result found for keyword: <span className="text-primary">{keyword}</span>.
                                                    Please change search keyword
                                                </h5>
                                            </div> :
                                            <div className="wrap__search-result-keyword">
                                                <h5>
                                                    Search results for keyword: <span className="text-primary">{keyword}</span> found in
                                                    <span className="text-primary">{' ' + resultSize}</span> {resultSize === 1 ? ' post' : ' posts'}.
                                                </h5>
                                            </div>
                                        }

                                        {dataLoaded ? searchResult.map((rec, key) =>
                                            <div key={key} className="card__post card__post-list card__post__transition mt-30">
                                                <div className="row ">
                                                    <div className="col-md-5">
                                                        <div className="card__post__transition">
                                                            <Link to={"/blog/post/" + rec.url}>
                                                                <img src={rec.image_url} className="img-fluid w-100" alt="" />
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-7 my-auto pl-0">
                                                        <div className="card__post__body">
                                                            <div className="card__post__content">
                                                                <div className="card__post__category">{rec.category}</div>
                                                                <div className="card__post__author-info mb-2">
                                                                    <ul className="list-inline">
                                                                        <li className="list-inline-item">
                                                                            <span className="text-primary text-capitalize">{rec.date_formatted_full}</span>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="card__post__title">
                                                                    <h5>
                                                                        <Link to={"/blog/post/" + rec.url}>{rec.title}</Link>
                                                                    </h5>
                                                                    <p className="d-none d-lg-block d-xl-block mb-0">{rec.content.substring(0, 141)}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>) :
                                            <div className="card__post card__post-list card__post__transition mt-30"></div>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
                <Chevron />
            </div>
        );
    }
}

export default (props) => (<BlogSearch {...props} params={useParams()} />);;